<template>
  <TableWrapper class="table-data-view table-responsive">
    <a-table :pagination="false" :dataSource="dataSource" :loading="isLoading" :columns="columns">
      <template #operableType="record">
        <p>{{ record.record.comment }}</p>
      </template>
      <template #amount_operation="record">
        <p>
          {{ `${record.record.amount_operation} ${record.record.operation_currency}` }}
        </p>
      </template>
      <!--<template #customTitleSystem>
        <TableCustomTitle
          name="system"
          :sortData="{
            value: queryData.sort,
            order: queryData.order,
          }"
          @sortTable="sortTable"
        />
      </template>
      <template #customTitleWalletName>
        <TableCustomTitle
          name="name"
          :sortData="{
            value: queryData.sort,
            order: queryData.order,
          }"
          @sortTable="sortTable"
        />
      </template>
      <template #customTitleDate>
        <TableCustomTitle
          name="created_at"
          :sortData="{
            value: queryData.sort,
            order: queryData.order,
          }"
          @sortTable="sortTable"
        />
      </template>
      <template #customTitleStatus>
        <TableCustomTitle
          name="status"
          :sortData="{
            value: queryData.sort,
            order: queryData.order,
          }"
          @sortTable="sortTable"
        />
      </template>-->
    </a-table>
    <div style="text-align: right">
      <a-pagination
        :default-page-size="10"
        :show-size-changer="true"
        v-model:current="pagination.current"
        :total="pagination.count"
        @change="onChange"
        v-if="pagination"
      >
        <template #buildOptionText="props">
          <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
        </template>
      </a-pagination>
    </div>
  </TableWrapper>
</template>

<script>
// import { defineAsyncComponent, computed, reactive, onMounted } from 'vue';
import { computed, reactive, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import { TableWrapper } from '../../styled';
// const TableCustomTitle = defineAsyncComponent(() => import('./TableCustomTitle'));

export default {
  props: {
    transactions: {
      type: Object,
      default() {
        return {};
      },
    },
    isLoading: {
      type: Boolean,
      defaul: true,
    },
  },

  components: {
    TableWrapper,
    // TableCustomTitle,
  },
  setup(props) {
    const i18n = reactive(useI18n());
    const { dispatch } = useStore();
    const route = useRoute();
    const router = useRouter();

    const pagination = computed(() => props.transactions.meta);

    const columns = computed(() => [
      {
        title: i18n.t('transactions.comment'),
        dataIndex: 'operable_type',
        key: 'operable_type',
        slots: { customRender: 'operableType' },
      },
      {
        title: i18n.t('transactions.amount_operation'),
        dataIndex: 'amount_operation',
        key: 'amount_operation',
        slots: { customRender: 'amount_operation' },
      },
      {
        title: i18n.t('transactions.balance'),
        dataIndex: 'balance',
        key: 'balance',
      },
      {
        title: i18n.t('transactions.account'),
        dataIndex: 'kind',
        key: 'kind',
      },
      {
        title: i18n.t('transactions.status'),
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: i18n.t('transactions.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
      },
      // {
      //   title: i18n.t('transactions.operable_type'),
      //   dataIndex: 'operable_type_short',
      //   key: 'operable_type_short',
      // },
      // {
      //   dataIndex: 'system',
      //   key: 'system',
      //   slots: { title: 'customTitleSystem', customRender: 'customStatus' },
      // },
      // {
      //   dataIndex: 'name',
      //   key: 'name',
      //   slots: { title: 'customTitleWalletName' },
      // },
      // {
      //   title: i18n.t('editWalletView.walletNumber'),
      //   dataIndex: 'wallet',
      //   key: 'wallet',
      // },
      // {
      //   dataIndex: 'created_at',
      //   key: 'created_at',
      //   slots: { title: 'customTitleDate' },
      // },
      // {
      //   dataIndex: 'status',
      //   key: 'status',
      //   slots: { title: 'customTitleStatus' },
      // },
      // {
      //   title: i18n.t('editWalletView.comment'),
      //   dataIndex: 'comment',
      //   key: 'comment',
      // },
      // {
      //   title: '',
      //   width: 0,
      // },
    ]);

    const dataSource = computed(() =>
      props.transactions.data
        ? props.transactions.data.map((transaction, key) => {
            const { attributes, id } = transaction;
            const {
              operable_type,
              amount_before,
              amount_after,
              amount_operation,
              operation_currency,
              created_at,
              status,
              operation_info,
              comment,
              account,
            } = attributes;
            return {
              key: key + 1,
              id,
              operable_type,
              balance: (
                <p style="display:flex;">
                  {' '}
                  {i18n.n(+amount_before, 'decimal')} {i18n.t('transactions.' + operation_currency)}{' '}
                  <span
                    style="margin:0 10px; white-space:nowrap;word-wrap:nowrap;"
                    class={+amount_after > +amount_before ? 'arrow-more' : 'arrow-less'}
                  >
                    ->
                  </span>{' '}
                  {i18n.n(+amount_after, 'decimal')} {i18n.t('transactions.' + operation_currency)}
                </p>
              ),
              operable_type_short: i18n.t('transactions.' + operable_type, 2),
              operation_currency: i18n.t('transactions.' + operation_currency),
              amount_operation: i18n.n(+amount_operation, 'decimal'),
              created_at: i18n.d(created_at, 'long'),
              status: i18n.t('transactions.' + status),
              normal_date: created_at,
              operation_info,
              comment,
              kind: i18n.t('transactions.accounts.' + account.kind),
            };
          })
        : [],
    );

    const pageNum = computed(() => route.params.pageNum);

    const queryData = reactive({
      pageNum: pageNum.value,
      pageSize: 10,
      order: 'desc',
      sort: 'id',
      sortableLink: `&sort_by=id&order=desc`,
    });

    const reloadTable = () => {
      dispatch('transactionsGetData', queryData);
    };

    const sortTable = (value) => {
      if (queryData.sort == value) {
        queryData.order == 'asc' ? (queryData.order = 'desc') : (queryData.order = 'asc');
      }

      queryData.sort = value;
      queryData.sortableLink = `&sort_by=${value}&order=${queryData.order}`;
      reloadTable();
    };

    const onChange = (page) => {
      router.push('/transactions/' + page);
      queryData.pageNum = page;
      reloadTable();
    };

    onMounted(() => {
      // if (!props.transactions.data.length) {
      reloadTable();
      // }
    });
    return {
      i18n,
      pagination,
      columns,
      dataSource,
      onChange,
      sortTable,
      queryData,
    };
  },
};
</script>

<style></style>
